.profile {
    margin-top: 200px;
}

.profile_container {
    display: flex;
}

.profile_image {
    margin: 10px 25px;
    width: 50%;
    text-align: center;
}

.profile_image img {
    margin: 0 auto;
    width: 100%;
}

.profile_text {
    margin: 10px 25px;
    width: 50%;
}

.profile_icons {
    margin-top: 10px;
}
.profile_icons a {
    margin: 0 5px;
}

@media screen and (max-width: 800px) {
    .profile_container {
        flex-direction: column;
        padding: 30px;
    }
    .profile_image {
        width: 100%;
        margin: 0;
    }
    .profile_text {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .profile_image {
        display: none;
    }
}
