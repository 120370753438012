header {
    padding-top: 50px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background: black;
    padding-bottom: 30px;
}

.header-logo {
    text-align: center;
    width: 100%;
}

header ul {
    padding: 15px 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
}

header li {
    font-weight: 500;
    list-style: none;
    margin: 0 15px;
}

header li a {
    text-decoration: none;
    color: #ffffff;
}

header li a:hover {
    text-decoration: underline;
}
