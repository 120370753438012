body {
  background: #000;
  font-family: "DM Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 80px;
  color: #ffffff;
  font-weight: 900;
  margin: 0;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  color: #ffffff;
  margin: 0;
  margin-top: 5px;
  line-height: 30px;
}

@media screen and (max-width: 800px) {
  h2 {
    font-size: 40px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
}
