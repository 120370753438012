footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer {
    border-radius: 30px 30px 0px 0px;
    background: #fff;
    padding: 8px 0;
}

.footer_open {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.footer_open:hover .footer_open--line {
    height: 15px;
    cursor: pointer;
}

.footer_open:hover .footer_open--button {
    padding: 2px;
    cursor: pointer;
}

.footer_open--line {
    background: #f8ff64;
    width: 40%;
    height: 9px;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: all 0.2s;
}

.footer_open--button {
    background: #f8ff64;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 6px;
    transition: all 0.2s;
}

.footer_header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.footer_header a {
    margin: 0 15px;
}

.footer_content {
    transition: all 0.5s;
}

.footer_content.close {
    opacity: 0;
    height: 0px;
}

.footer_content.open {
    opacity: 1;
    height: 150px;
}

.close h4 {
    margin: 0;
}

.footer_content h4 {
    margin: 10px 0;
    text-align: center;
}

.footer_content--networks {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    margin: 20px 0 40px 0;
}
