.experience_item {
    margin-bottom: 50px;
    border-bottom: 1px solid gray;
    padding-bottom: 30px;
}

.experience_item_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.experience_item_title-company h3 {
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
}

.experience_item_title-company h4 {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0;
}

.experience_item_title-year {
    color: #fff;
    text-align: right;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.experience_item_description {
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 800px) {
    .experience_item_title {
        align-items: flex-start;
    }

    .experience_item_title-company h3 {
        font-weight: 600;
    }

    .experience_item_title-year {
        font-weight: 400;
    }

    .experience_item_description {
        font-size: 14px;
        line-height: 22px;
    }
}
